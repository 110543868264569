import BackgroundImage from "gatsby-background-image"
import { ArrowDownIcon, ArrowLongRightIcon } from "@heroicons/react/24/solid"
import React from "react"
import { FreeMode, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"
import { Link } from "gatsby"

const HeroSlider = props => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          el: ".my-custom-pagination-div-hero",
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"> </span>'
          },
        }}
      >
        {props.slides.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <BackgroundImage
                {...item.image}
                className="h-screen  w-full bg-yellow/50"
              >
                <div className="container mx-auto md:px-20 px-10 h-full">
                  <div className="flex items-center justify-center md:justify-start  h-full">
                    <div>
                      <div className=" font-bold text-5xl text-center lg:text-left md:text-8xl text-white">
                        {item.titile}
                      </div>
                      <div className="mt-3 text-xl font-semibold text-center lg:text-left text-white">
                        {item.short}
                      </div>
                      <div className="uppercase font-light text-sm tracking-widest">
                        <div className="mt-5 flex justify-center lg:justify-start">
                          <Link to={item.linkmore}>
                            <button
                              type="submit"
                              className=" tracking-wide uppercase border border-yellow bg-yellow text-black  font-semibold rounded-full px-8 text-lg py-4 hover:border-black duration-200 flex gap-3 items-center cursor-pointer"
                            >
                              {item.btnname}
                              <div>
                                <ArrowLongRightIcon className="h-4 w-4 stroke-black" />
                              </div>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BackgroundImage>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

export default HeroSlider
