import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import ReactPlayer from "react-player"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  ArrowDownIcon,
  ArrowLongRightIcon,
  ArrowRightIcon,
  BellIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import CsrSlider from "../components/sliders/csr-slider"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import HeroSlider from "../components/sliders/hero-slider"
import CavingSlider from "../components/sliders/caving-slider"

const IndexPage = ({ data }) => {
  const images = {
    watawala_tea: getImage(data.watawala_tea),
    ayurweda_herbal_tea: getImage(data.ayurweda_herbal_tea),
    haritha_tea: getImage(data.haritha_tea),

    badge: getImage(data.badge),
    leaf: getImage(data.leaf),
    leafbox: getImage(data.leafbox),
    mug: getImage(data.mug),
    pot: getImage(data.pot),

    newicon01: getImage(data.newicon01),
    newicon02: getImage(data.newicon02),
    newicon03: getImage(data.newicon03),
    newicon04: getImage(data.newicon04),
    newicon05: getImage(data.newicon05),

    blogOne: getImage(data.blogOne),
    blogTwo: getImage(data.blogTwo),
    blogThree: getImage(data.blogThree),
    blogFour: getImage(data.blogFour),
    blogFive: getImage(data.blogFive),
    blogSix: getImage(data.blogSix),
    blogSeven: getImage(data.blogSeven),
    blog8: getImage(data.blog8),

    heroOne: convertToBgImage(getImage(data.heroOne)),
    heroTwo: convertToBgImage(getImage(data.heroTwo)),
    heroThree: convertToBgImage(getImage(data.heroThree)),

    teaBg: convertToBgImage(getImage(data.teaBg)),

    gardning: convertToBgImage(getImage(data.gardning)),

    IcedTea: convertToBgImage(getImage(data.IcedTea)),
    ginger: convertToBgImage(getImage(data.ginger)),
    tea: convertToBgImage(getImage(data.tea)),

    csrBg: convertToBgImage(getImage(data.csrBg)),
  }

  const blogs = [
    {
      image: images.blog8,
      title: "Sharing the joys of Christmas with a friendly chat",
      anchor: "/sharing-the-joys-christmas-with-friendly-chat/",
    },

    {
      image: images.blogOne,
      title: "දිරියට කෘතගුණ  Children’s Day 2022",
      anchor: "/childrens-day-22/",
    },
    {
      image: images.blogTwo,
      title: "Watawala Tea Rings in Children’s Day with Fun-Filled Event",
      anchor: "/watawala-tea-rings-in-children-day/",
    },
    {
      image: images.blogThree,
      title: "Inspiring Ethnic Harmony through Gratitude ",
      anchor: "/inspiring-ethnic-harmony-through-gratitude/",
    },
    {
      image: images.blogFour,
      title: "Watawala Scholarship Programme 2020  ",
      anchor: "/watawala-scholarship-programme-2020/",
    },
    {
      image: images.blogFive,
      title: "Watawala Tea Drinking Water Project",
      anchor: "/drinking-water-project%E2%80%93kataragama/",
    },
    {
      image: images.blogSix,
      title: "Watawala Tea Joins the Fight Against COVID-19",
      anchor: "/watawala-tea-joins-the-fight-against-covid-19/",
    },
    {
      image: images.blogSeven,
      title: "Celebrating Avurudu with the Farming Community",
      anchor: "/celebrating-avurudu-with-the-farming-community/",
    },
  ]

  const quality = [
    {
      icon: images.newicon02,
      title: "Taste",
      description: "Unique blends to provide perfect taste and aroma      ",
    },
    {
      icon: images.newicon04,
      title: "Selection",
      description: "A tea for everyone and every occasion",
    },
    {
      icon: images.newicon03,
      title: "Origin",
      description: "Exclusively grown and produced in Sri Lanka ",
    },
    {
      icon: images.newicon01,
      title: "Process",
      description: "Certified for quality and safety, end to end ",
    },
    {
      icon: images.newicon05,
      title: "Packaging",
      description: "Packaged to preserve the full flavour profile",
    },
  ]

  const products = [
    {
      image: images.watawala_tea,
      title: "watawala tea",
      description: "For a refreshing cup of pure Ceylon tea  ",
      link: "/watawala-tea/",
    },
    {
      image: images.ayurweda_herbal_tea,
      title: "Ayur Tea ",
      description: "An immune-boosting ayurvedic herbal tea ",
      link: "/ayur-tea/",
    },
    {
      image: images.haritha_tea,
      title: "Haritha Tea ",
      description: "A fresh green tea full of benefits        ",
      link: "/haritha-tea/",
    },
  ]

  const caving = [
    {
      image: images.IcedTea,
      title: "Iced Tea",
      description:
        "Cool and invigorating, iced tea can be enjoyed sweet or unsweetened.",
      link: "/blog/#blog8",
    },
    {
      image: images.ginger,
      title: "Ginger Tea",
      description:
        "Spicy but soothing, ginger tea makes for a great night cap.",
      link: "/blog/#blog9",
    },
    {
      image: images.tea,
      title: "Masala Tea      ",
      description:
        " Creamy and flavourful, masala tea makes a delicious pick-me-up. ",
      link: "/blog/#blog10",
    },
  ]

  const heroSlides = [
    {
      image: images.heroOne,
      titile: "Watawala Tea ",
      short: "Sip on the finest Sri Lankan grown teas",
      btnname: "Explore",
      linkmore: "/wawanna/#competition",
    },
    {
      image: images.heroThree,
      titile: "#වවන්න ",
      short: "Joining hands to combat a food crisis",
      btnname: "Participate",
      linkmore: "/wawanna/",
    },
    {
      image: images.heroTwo,
      titile: "දිරියට කෘතගුණ ",
      short: "Supporting children who help support their families ",
      btnname: "learn more",
      linkmore: "/childrens-day-22/",
    },
  ]

  return (
    <>
      <Layout>
        <Seo title="Home | Watawala Ceylon Tea: Unrivalled Freshness & Flavour from Central Sri Lanka" />
        <section>
          <div className="relative">
            <HeroSlider slides={heroSlides} />
            <div className="flex absolute bottom-0 z-10 mb-5 w-full justify-center mt-10">
              <div className="flex gap-3">
                <div className=" my-custom-pagination-div-hero"></div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container md:px-20 px-10 mx-auto py-20 overflow-hidden">
          
          <h1 hidden>"Discover the Epitome of Tea Excellence: Experience the Finest Selection of Watawala's Best Teas"</h1>
            <div className="mb-14">
              <div className="flex justify-center">
                <div>
                  <div className="flex justify-center">
                    <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                      Products
                    </div>
                  </div>
                  <div className="uppercase text-3xl max-w-md leading-normal text-center mx-auto mb-4 tracking-widest">
                    OUR TEAS
                  </div>
                  <div className="max-w-4xl text-center mx-auto font-light text-lg">
                    Shop our range of exquisite teas, blended to suit the
                    different tastes of Sri Lankan tea lovers.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-14">
                {products.map((item, index) => {
                  return (
                    <div
                      className="bg-white shadow-center rounded-xl p-10   shadow-xl		hover:bg-yellow/10   hover:transition-all	   border    hover:border-yellow"
                      key={index}
                    >
                      <GatsbyImage
                        image={item.image}
                        className=""
                        alt="Watawala"
                      />
                      <div className="uppercase font-semibold tracking-widest mt-5 text-xl text-center">
                        {item.title}
                      </div>
                      <div className="font-light text-center mt-5">
                        {item.description}
                      </div>
                      <div className="flex justify-center mt-7">
                        <div className="uppercase font-light tracking-widest">
                          <div className="">
                            <button
                              type="submit"
                              className=" tracking-wide uppercase border border-yellow bg-yellow text-black  font-semibold rounded-full px-6 py-2 hover:border-black duration-200 flex gap-3 items-center cursor-pointer"
                            >
                              <Link to={item.link}> shop now </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div>
            <div className="h-[90vh] w-full bg-gray-300">
              <BackgroundImage {...images.teaBg} className="h-full">
                <div className="container mx-auto md:px-20 px-10 h-full">
                  <div className="flex items-center  h-full">
                    <div className="flex justify-end w-full">
                      <div>
                        <div className="">
                          <div className="uppercase text-3xl max-w-lg leading-normal mb-4 tracking-widest">
                            Journey into the world of tea
                          </div>
                          <div className="max-w-3xl  mx-auto font-light text-lg">
                            At Watawala Tea, we understand tea and the adventure
                            it can be, from its unique taste and wonderful aroma
                            to its ability to connect people over a shared pot
                            of tea.
                          </div>
                          <div className="flex  mt-7">
                            <Link to="/watawala-tea/">
                              <button className=" tracking-wide uppercase border border-black font-light rounded-full px-6 py-2 hover:bg-yellow hover:border-yellow duration-200">
                                learn more
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BackgroundImage>
            </div>
          </div>
        </section>
        <section>
          <div className="container md:px-20 px-10 mx-auto py-20 overflow-hidden">
            <div className="mb-14">
              <div className="flex justify-center">
                <div>
                  <div className="flex justify-center">
                    <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                      Why choose us
                    </div>
                  </div>
                  <div className="uppercase text-3xl max-w-md leading-normal text-center mx-auto mb-4 tracking-widest">
                    Highest Quality Teas
                  </div>
                  <div className="max-w-4xl text-center mx-auto font-light text-lg">
                    From tree to cup, we ensure the highest levels of quality
                    are met, so you can enjoy your favourite Watawala tea in its
                    full glory.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-10 lg:grid-cols-5">
                {quality.map((item, index) => {
                  return (
                    <div className="flex justify-center" key={index}>
                      <div className="">
                        <div className="text-center">
                          <div className="flex justify-center">
                            <div className="rounded-full bg-yellow w-24 h-24 relative">
                              <div className="absolute inset-0 flex justify-center items-center">
                                <GatsbyImage
                                  src="Watawala"
                                  image={item.icon}
                                  className="w-12"
                                  alt="Watawala"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 font-semibold">{item.title}</div>
                          <div className="mt-2 font-light">
                            {item.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section>
          <BackgroundImage {...images.gardning} className="bg-blue-200 ">
            <div className="container md:px-20 px-10 mx-auto">
              <div className="py-20">
                <div className="lg:flex-row flex-col flex gap-14">
                  <div>
                    <StaticImage
                      src="../images/icons/bell.png"
                      alt="Watawala"
                      className="w-14"
                    />
                  </div>
                  <div>
                    <div className="font-semibold text-xl">
                      Join #වවන්න to Fight the Food Crisis
                    </div>
                    <div className="mt-5 mb-7 max-w-4xl font-light">
                      ‘වවන්න’ is a national campaign by Watawala Tea to
                      revitalise home gardening in Sri Lanka, empowering
                      households to put the fertile soil of this nation to good
                      use by cultivating produce and combat the hunger crisis
                      that looms in our collective future.
                    </div>
                    <div className="flex">
                      <button className=" tracking-wide uppercase border border-white bg-white font-light rounded-full px-6 py-2 hover:text-yellow hover:border-yellow duration-200">
                        <Link to="/wawanna"> learn more </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </section>
        <section>
          <div className="container md:px-20 px-10 mx-auto py-20 overflow-hidden">
            <div className="mb-14">
              <div className="flex justify-center">
                <div>
                  <div className="flex justify-center">
                    <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                      TEA RECIPES
                    </div>
                  </div>
                  <div className="uppercase text-3xl max-w-md leading-normal text-center mx-auto mb-4 tracking-widest">
                    What’s Your Tea Craving?
                  </div>
                  <div className="max-w-4xl text-center mx-auto font-light text-lg">
                    Hot or cold, sweet or spicy, we have a recipe to enjoy your
                    favourite tea in a new way.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <CavingSlider slides={caving} />
              <div className="flex z-10 mb-5 w-full justify-center mt-10">
                <div className="flex gap-3">
                  <div className=" my-custom-pagination-div-caving"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="bg-yellow/50">
            <BackgroundImage {...images.csrBg}>
              <div className="container md:px-20 px-10 mx-auto py-20 overflow-hidden">
                <div className="mb-14">
                  <div className="flex justify-center">
                    <div>
                      <div className="flex justify-center">
                        <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                          watawala CSR
                        </div>
                      </div>
                      <div className="uppercase text-3xl max-w-3xl leading-normal text-center mx-auto mb-4 tracking-widest">
                        Empowering a nation, one step at a time
                      </div>
                      <div className="max-w-4xl text-center mx-auto font-light text-lg">
                        We believe in contributing towards society by supporting
                        the underprivileged in areas they need the most help.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <CsrSlider slides={blogs} />
                </div>
              </div>
            </BackgroundImage>
          </div>
        </section>
        {/* seo  */}
        <section>
          <div className="" hidden>
            <div>
              https://watawalatea.lk/watawala-tea
            </div>

            Watawala Tea is a well-known brand of tea in Sri Lanka, which is a major producer and exporter of tea in the world. The Watawala Tea Ceylon Limited is a subsidiary of the Sunshine Holdings PLC, and it produces and markets a wide range of teas, including black tea, green tea, flavored tea, and herbal tea.

            Watawala Tea is known for its high-quality teas, which are grown in the misty hills of Sri Lanka's central highlands. The company follows a strict process of tea production, from hand-picking the tea leaves to packaging the final product. This ensures that the tea is fresh, fragrant, and flavorful, and it has won the company numerous awards for its quality.

            Overall, if you are a tea lover, Watawala Tea is definitely worth trying, especially if you enjoy Ceylon tea, which is known for its unique aroma and flavor.
          </div>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query HomeImages {
    watawala_tea: file(relativePath: { eq: "products/watawala_tea.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    ayurweda_herbal_tea: file(
      relativePath: { eq: "products/ayurweda_herbal_tea.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    haritha_tea: file(relativePath: { eq: "products/haritha_tea.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    newicon01: file(relativePath: { eq: "pages/home/icon/01icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    newicon02: file(relativePath: { eq: "pages/home/icon/02icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    newicon03: file(relativePath: { eq: "pages/home/icon/03icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    newicon04: file(relativePath: { eq: "pages/home/icon/04icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    newicon05: file(relativePath: { eq: "pages/home/icon/05icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogOne: file(relativePath: { eq: "pages/news/csrnews1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogTwo: file(relativePath: { eq: "pages/news/csrnews2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogThree: file(relativePath: { eq: "pages/news/csrnews3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogFour: file(relativePath: { eq: "pages/news/csrnews4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogFive: file(relativePath: { eq: "pages/news/csrnews5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogSix: file(relativePath: { eq: "pages/news/csrnews6.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogSeven: file(relativePath: { eq: "pages/news/csrnews7.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blog8: file(relativePath: { eq: "pages/news/csrnews8.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    heroOne: file(relativePath: { eq: "pages/home/heroOne.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    heroTwo: file(relativePath: { eq: "pages/home/heroTwo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    heroThree: file(relativePath: { eq: "pages/home/heroThree.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    teaBg: file(relativePath: { eq: "pages/home/teaBg.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gardning: file(relativePath: { eq: "pages/home/garding-full.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    IcedTea: file(relativePath: { eq: "pages/home/IcedTea.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    ginger: file(relativePath: { eq: "pages/home/ginger.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    tea: file(relativePath: { eq: "pages/home/tea.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    csrBg: file(relativePath: { eq: "pages/home/csrBg.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
